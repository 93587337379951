import React, { useContext, useState } from "react"
import { motion } from "framer-motion"
import { Location } from "@reach/router"

import Button from "../Button/Button"

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/GlobalContextProvider"

import "./mobile-footer.css"

const MobileFooter = ({ productDetails, productImages }) => {
  // Global state and actions
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  // Set ref to top of page for scroll to top
  const [topAnchor, setTopAnchor] = React.useState(false)
  React.useEffect(
    () => setTopAnchor(document.getElementById("page-container")),
    []
  )

  // Hold state for adding to cart
  const [itemAlreadyAdded, setItemAlreadyAdded] = useState(false)

  const handleAddToCart = () => {
    // Check if item is already in cart
    if (
      state.cart.some(x => {
        return x.product.productName === productDetails.productName
      })
    ) {
      //  Don't add item if already in cart
      setItemAlreadyAdded(true)
      setTimeout(() => {
        setItemAlreadyAdded(false)
      }, 2500)
    } else {
      // Add item if not already in cart
      dispatch({
        type: "ADD_ITEM",
        payload: {
          product: {
            ...productDetails,
            productImages: { ...productImages },
          },
        },
      })
    }
  }

  const ToTopButton = () => (
    <Button
      text={"BACK TO TOP"}
      className={"footer-button footer-to-top-button"}
      onClick={() => {
        topAnchor.scrollIntoView({ behavior: "smooth" })
      }}
    />
  )

  const ToCartButton = () => (
    <Button
      text={`CART(${state.cart.length})`}
      className={"footer-button footer-to-cart-button"}
      to={"/cart"}
    />
  )

  const AddItemButton = () => (
    <Button
      text={itemAlreadyAdded ? "ITEM ALREADY ADDED" : "ADD ITEM TO CART"}
      onClick={handleAddToCart}
      className={"footer-button footer-add-item-button"}
    />
  )

  const ContinueShoppingButton = () => (
    <Button
      text={"CONTINUE SHOPPING"}
      to={"/"}
      className={"footer-button footer-continue-shopping-button"}
    />
  )

  const ToCheckoutButton = () => (
    <Button
      text={"PROCEED TO CHECKOUT"}
      className={"footer-button"}
      to={"/checkout"}
    />
  )

  const ToPaymentButton = () => (
    <Button
      text={"PROCEED TO PAYMENT"}
      className={"footer-button footer-to-payment-button"}
    />
  )

  return (
    <>
      <motion.div id={"footer-container"}>
        <Location>
          {({ location }) => {
            switch (location.pathname) {
              case "/":
                return (
                  <>
                    <ToTopButton />
                    <ToCartButton />
                  </>
                )
              case "/cart":
                return (
                  <>
                    <ContinueShoppingButton />
                    <ToCheckoutButton />
                  </>
                )
              case "/checkout":
                return (
                  <>
                    <ToCartButton />
                    <ToPaymentButton />
                  </>
                )
              // All product pages
              default:
                return (
                  <>
                    <AddItemButton />
                    <ToCartButton />
                  </>
                )
            }
          }}
        </Location>
      </motion.div>
    </>
  )
}

export default MobileFooter
