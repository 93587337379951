import React, { useContext } from "react"
import { motion } from "framer-motion"
import { Location } from "@reach/router"

import Button from "../Button/Button"

import { GlobalStateContext } from "../../context/GlobalContextProvider"

import "./desktop-footer.css"

const DesktopFooter = () => {
  const [topAnchor, setTopAnchor] = React.useState(false)

  const state = useContext(GlobalStateContext)

  React.useEffect(
    () => setTopAnchor(document.getElementById("page-container")),
    []
  )

  const ToTopButton = () => (
    <Button
      text={"BACK TO TOP"}
      className={"footer-button footer-to-top-button"}
      onClick={() => {
        topAnchor.scrollIntoView({ behavior: "smooth" })
      }}
    />
  )

  const ToCartButton = () => (
    <Button
      text={`CART(${state.cart.length})`}
      className={"footer-button"}
      to={"/cart"}
    />
  )

  const ToPaymentButton = () => (
    <Button text={"PROCEED TO PAYMENT"} className={"footer-button"} />
  )

  return (
    <>
      <motion.div id={"footer-container"}>
        <motion.div id={"footer-button-container"}>
          <Location>
            {({ location }) => {
              switch (location.pathname) {
                case "/":
                  return (
                    <div className={"index-footer-button-container"}>
                      <ToTopButton />
                      <ToCartButton />
                    </div>
                  )
                case "/cart":
                  return (
                    <>
                      <ToTopButton />
                      <ToCartButton />
                    </>
                  )
                case "/checkout":
                  return (
                    <>
                      <ToCartButton />
                      <ToPaymentButton />
                    </>
                  )
                // All product pages
                default:
                  return (
                    <>
                      <ToTopButton />
                      <ToCartButton />
                    </>
                  )
              }
            }}
          </Location>
        </motion.div>
        <motion.div id={"social-footer-links"}>
          <motion.p className={"paragraph uppercase"}>
            FIND US ON{" "}
            <a
              href={"https://www.instagram.com/immaterialcorp/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              INSTAGRAM
            </a>{" "}
            OR AT{" "}
            <a
              href={"https://g.page/im-material-amsterdam?share"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Haarlemmerdijk 171, 1013 KH Amsterdam
            </a>
          </motion.p>
        </motion.div>
      </motion.div>
    </>
  )
}

export default DesktopFooter
